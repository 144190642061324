<template>
    <div>
        <ReportCenterTable
            :page-footer="true"
            :deleteUrl="'api/patient/report_delete'"
            :Adownload="true"
            :Adelete = "true"
            :search-url="url"
            :InfoHref="InfoHref"
            :tableTitle="tableTitle"
            detail-href="TestReport"
            @zip_add = "zip_addClick"
            @delinfo="delinfoChange"
            :tableType = "1"
            @remove="removeBtn"/>
        <el-dialog :visible.sync="qrDialog" :modal="false" width="22vw" top="25vh" :close-on-click-modal="false">
            <div class="dialog-body">
                <div class="dialog-text" style="text-align: center">
                    <img src="../assets/images/tishi.png" style="width: 80px;height: 80px">
                    <h2 style="margin-top: 20px;margin-bottom: 10px;">下载提示</h2>
                    <div style="margin-top: 5px; margin-bottom: 20px" >
                        <h3>打包任务已提交，请稍后在信息中心进行下载</h3>
                    </div>
                    <el-button type="warning" style="background: #8197FE;border: 1px solid #8197FE;color: #FFFFFF;box-shadow:none;" round @click="qrDialog = false">知道了
                    </el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="qrDialog_del" :modal="false" width="22vw" top="25vh" :close-on-click-modal="false">
            <div class="dialog-body">
                <div class="dialog-text" style="text-align: center">
                    <img src="../assets/images/tishi.png" style="width: 80px;height: 80px">
                    <h2 style="margin-top: 20px;margin-bottom: 10px;">提示</h2>
                    <div style="margin-top: 5px; margin-bottom: 20px" >
                        <h3>{{ delinfoList }}</h3>
                    </div>
                    <div slot="footer">
                        <el-button type="warning" style="background: #ffffff;border: 1px solid #CDCDCD;color: #333333;box-shadow:none;" round @click="deleteZJ">直接删除
                        </el-button>
                        <el-button type="primary" round @click="buttonSubmit">一键下载</el-button>
                    </div>
                </div>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import ReportCenterTable from "../components/ReportCenterTable";



export default {
    name: "ReportCenter",
    components: {ReportCenterTable},
    data() {
        return {
            tableTitle: [
                {
                    name: '编号',
                    value: 'num'
                },
                {
                    name: '患者',
                    value: 'name'
                },
                {
                    name: '病历号',
                    value: 'medical_num',
                },
                {
                    name: '医保号',
                    value: 'medical_insurance_num'
                }],
            url: 'api/patient/index',
            InfoHref: 'InfoAssess',
            qrDialog: false,
            qrDialog_del:false,
            delinfoList:'',
            delid:'',
            delidxzL:'',
        };

    },
    computed: {},
    watch: {},
    methods: {
        deleteZJ() {
            this.$axios.post('api/patient/report_delete', this.$qs.stringify({
                id: this.delid,
                type:1
            })).then(res => {
                this.$refs.table.onRefresh()
                this.qrDialog_del = false;
            })
        },
        buttonSubmit() {
            this.$axios.post('api/zip/zip_add', this.$qs.stringify({
                patient_id: this.delidxzL,
            })).then(res => {
                if(res.data.code == 1) {
                    this.qrDialog = true
                    this.qrDialog_del = false;
                }

            })
        },
        delinfoChange(res,id,delid) {
            this.qrDialog_del = true;
            this.delinfoList = res.data.name
            this.delidxzL = id
            this.delid = delid
        },
        zip_addClick(res) {
            if(res) {
                this.qrDialog = true
            }
        },
        add() {
        },
        removeBtn(ids) {
            console.log(ids)
        }
    },
    mounted() {
        /*
        ipcreaneder.on('sendWebsock',(event,obj)=>{
            console.log(obj);
        })*/
    }
}
</script>

<style scoped>

</style>
